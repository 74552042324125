.Toastify__toast--success {
  background-color: #51a351;
}

.Toastify__toast--info {
  background-color: #2f96b4;
}

.Toastify__toast--warning {
  background-color: #f89406;
}

.Toastify__toast--danger {
  background-color: #bd362f;
}