
.neon-text {
  color: transparent;
  font-size: 16px;
  display: flex;
  font-style: italic;

  span {
    animation: animate 4s linear infinite;
    animation-delay: calc(0.1s * var(--i));
  }
}

@keyframes animate {
  0% {
    color: #0015fa;
    filter: blur(0.5px) hue-rotate(0deg);
    text-shadow: 0 0 10px #00b3ff, 0 0 20px #00b3ff, 0 0 40px #00b3ff, 0 0 80px #00b3ff, 0 0 120px #00b3ff, 0 0 200px #00b3ff, 0 0 300px #00b3ff, 0 0 400px #00b3ff;
  }
  30%, 70% {
    color: #0015fa;
    filter: blur(0.5px) hue-rotate(360deg);
    text-shadow: 0 0 10px #00b3ff, 0 0 20px #00b3ff, 0 0 40px #00b3ff, 0 0 80px #00b3ff, 0 0 120px #00b3ff, 0 0 200px #00b3ff;
  }
  100% {
    color: transparent;
    box-shadow: none;
    filter: blur(0.5px) hue-rotate(0deg);
  }
}


.neon-button {
  font-size: 16px;
  border: 1px solid #14f7ff;
  color: #14f7ff;
  padding: 0.25em 1em;
  border-radius: 0.25em;
  text-shadow: 0 0 .20em #14f7ff, 0 0 .30em #14f7ff;
  box-shadow: inset 0 0 .8em #14f7ff,  0 0 .8em #14f7ff;
}

.neon-frame {
  width: fit-content;
  background: linear-gradient(0deg, black, rgb(44, 43, 43));
  position: relative;
}

.glow::before, .glow::after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  //background: linear-gradient(45deg, #e6fb04, #ff6600, #00ff66, #00ffff, #ff00ff, #ff0099, #6e0dd0, #ff3300, #099fff);
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: 0;
  animation: glow-animate 20s linear infinite;
  border-radius: 2px;
}

@keyframes glow-animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.glow::after {
  filter: blur(40px)
}

.youtube-item {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
  z-index: 2;

  &__title {
    font-weight: bold;
  }
}
