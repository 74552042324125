.wallet-card {
  width: calc(100% / 3);
  min-height: 140px;
  padding: 16px 20px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  border-left: 4px solid #354052;
  border-top: 1px solid #d3cece;
  border-right: 1px solid #d3cece;
  border-bottom: 1px solid #d3cece;

  &__amount {
    font-size: 1.75rem;
    font-weight: 600;
  }
}

.wallet-paid {
  .border-corner-qr {
    background: linear-gradient(to right, #c1c7cd 1px, transparent 1px) 0 0,
    linear-gradient(to right, #c1c7cd 1px, transparent 1px) 0 100%,
    linear-gradient(to left, #c1c7cd 1px, transparent 1px) 100% 0,
    linear-gradient(to left, #c1c7cd 1px, transparent 1px) 100% 100%,
    linear-gradient(to bottom, #c1c7cd 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, #c1c7cd 1px, transparent 1px) 100% 0,
    linear-gradient(to top, #c1c7cd 1px, transparent 1px) 0 100%,
    linear-gradient(to top, #c1c7cd 1px, transparent 1px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 10px 10px;
    padding: 8px;
    margin-top: 12px;

    &__inner {
      padding: 4px;
      border: 1px solid #C1C7CD;
      width: 166px;
      height: 170px
    }
  }

  .bank-block {
    border: 1px solid rgba(145, 158, 171, 0.24);
    border-radius: 5px;
    padding: 12px 20px;
    margin-top: 12px;
  }

  .payment-notice {
    padding: 16px 24px;
    background-color: rgb(244, 245, 246);
    border: 1px dashed #405CFF;
    border-radius: 8px
  }

  .text-second {
    color: #8B9299
  }
}
