.user-package-box {
  background: #354052;
  padding: 16px;
  border-radius: 8px;
  color: white;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.extend-button {
  padding: 0 20px;
  width: 100%;
  white-space: nowrap;
  background-color: #FFB90A;
  color: white;
  font-weight: 600 !important;
  border: none !important;

  &:hover {
    background-color: #efac05 !important;
    color: white !important;
  }
}
