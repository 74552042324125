.share-qr-modal {
  background: #141414;
  border-radius: 8px;

  .ant-modal-content {
    background: #141414;
    color: white;
    border-radius: 8px;
  }

  .share-qr-block {
    background: #141414;
  }

  .confirm {
    background: #ffc107;
    color: #000;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    width: 360px;

    &:hover {
      background: #e0a800;
    }
  }
}

.qr-box {
  border: 1px solid #575757;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 24px;
  max-width: 360px;
  margin: 36px auto 0;

  &__content {
    color: #ffc107;
    font-weight: bold;
  }
}