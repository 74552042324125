.package-card {
  width: calc(100% / 3);
  background-color: #354052;
  min-height: 140px;
  padding: 16px 20px;
  border-radius: 8px;
  color: white;
  position: relative;
  overflow: hidden;

  &__name {
    font-size: 1.75rem;
    font-weight: 600;
  }

  &__typo {
    font-size: 0.75rem;
    font-weight: 600;
  }

  .upgrade-button {
    max-width: 120px;
    width: 100%;
    white-space: nowrap;
    background-color: white;
    color: #354052 !important;
    font-weight: 600 !important;
    border: none !important;
    &:hover {
      background-color: rgb(244, 245, 246) !important;
    }
  }

  .extend-button {
    padding: 0 20px;
    width: 100%;
    white-space: nowrap;
    background-color: #FFB90A;
    color: white;
    font-weight: 600 !important;
    border: none !important;
    &:hover {
      background-color: #efac05 !important;
      color: white !important;
    }
  }
}
