.invite-friends {
  background: #001529;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  color: #fff;
  font-family: Arial, sans-serif;

  .invite-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    span {
      &.earned {
        font-size: 12px;
        color: #ffc107;

        span {
          font-weight: bold;
          color: #fff;
        }
      }
    }
  }

  .invite-section {
    margin-bottom: 20px;

    .invite-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #333;

      .exp {
        font-weight: bold;
        color: #ffc107;
      }

      .label {
        font-size: 12px;
        color: #aaa;
      }

      .count {
        font-weight: bold;
      }
    }
  }

  .referral-section {
    text-align: center;

    &-code {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0.5em;
      border: 1px solid #333;
      padding: 10px;

      &__label {
        color: #aaa
      }

      &__code {
        font-weight: bold;
        font-size: 14px;
        color: #ffc107;
        display: block;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      gap: 12px;

      button {
        width: 100%;
        background: #001529;
        color: white;
        border: 1px solid #333;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background: #e0a800;
        }
      }
    }

    .referral-input {
      width: 100%;
      padding: 5px;
      margin-bottom: 10px;
      border: 1px solid #444;
      border-radius: 5px;
      background: #001529;
      color: #fff;

      &::placeholder {
        color: #aaa;
      }
    }

    .confirm {
      background: #ffc107;
      color: #000;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      width: 100%;

      &:hover {
        background: #e0a800;
      }
    }
  }
}
