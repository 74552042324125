.sider {
    overflow: auto;
    height: 100vh;
    position: fixed !important;
    inset-inline-start: 0;
    top: 0;
    bottom: 0;
    scrollbar-width: thin;
    scrollbar-gutter: 'stable';

    .ant-menu-item {
        height: 34px;
    }

    .ant-menu-item-group {
        height: 30px;
    }
}
