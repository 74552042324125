.artwork-config-modal {
  .ant-upload.ant-upload-select {
    width: 100% !important;
    height: 160px !important;
  }
}

.avatar-uploader {
  margin-top: 12px;
}
